.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  
}
@media screen and (max-width: 768px) {
  /* Define your mobile-specific CSS styles here */
  .App {
    text-align: center;
    display: flex;
    flex-direction: column;
    
  }
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0a2235;
  min-height: 100vh;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  padding-left: 5%;
  padding-right: 5%;
  justify-content: center;
  color: white
}

.App-link {
  color: #61dafb;
}
